.custom-border-input input {
    background-color: white;
    border: 2px solid #63A6FD;
}

input:focus {
    outline: 0;
}

@media screen and (max-width: 321px) {
    .custom-logo {
        margin: auto;
        margin-left: 0;
    }
}