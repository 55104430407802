@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input[type='radio']:focus {
    box-shadow: none;
}

@layer components {
    .btn-primary {
        @apply text-white-100 bg-green-100 rounded-[100px]  hover:bg-green-300 text-m h-9 w-24 flex items-center justify-center leading-5 md:mb-2;
    }
    .btn-secondary {
        @apply bg-white-100 border-[1px] border-gray-700  mr-[5px] md:mr-[0px] rounded-[100px] text-green-100 hover:bg-gray-600 text-m h-9 w-32 flex items-center justify-center leading-5;
    }
}

@layer utilities {
    .scrollbar::-webkit-scrollbar {
        width: 10px;
        height: 20px;
        margin-right: 10px;
    }

    .scrollbar::-webkit-scrollbar-track {
        border-radius: 100vh;
        background: #f2f2f2;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: #ccdad1;
        border-radius: 100vh;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background: rgba(0, 96, 70, 0.25);
    }
}
