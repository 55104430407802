.wrapper-cookie {
    padding: 10px;
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #DFEFEC;
    box-shadow: 0px -2px 0px #0B6655;
    z-index: 3000;
    min-height: 110px;
    font-size: 13px;
    line-height: 20px;
    width: 100vw;
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.wrapper-cookie-hide{
    opacity: 0;
    display: 'none';
}

.cookie-description {
    max-width: 1000px;
    flex-wrap: wrap;
    font-weight: 500;
    line-height: 20px;
    color: #545454;
    padding: 15px;
}

.cookie-btns {
    display: flex;
    flex-wrap: nowrap;
    padding: 15px;
}

.cookie-setting{
    font-weight: 500;
    padding: 8px 30px 8px 30px;
    color: #006046;
    border: 1px solid #79747E;
    border-radius: 50px;
    min-width: 170px;
    height: 38px;
}

.cookie-accept{
    font-weight: 500;
    padding: 8px 30px 8px 30px;
    color: #fff;
    background-color: #006046;
    border-radius: 50px;
    min-width: 170px;
    height:38px;
}

.cookie-close{
    color: #545454;
    font-weight: 800;
    padding: 15px;
}

.cookie-close:hover {
    color: #B1B1B1;
}

.cookie-close::after{
    font-size: 40px;
    display: inline-block;
    content: "\00d7";
}

@media screen and (max-width: 427px) {
    .wrapper-cookie { 
        display: flex;
        flex-direction: column;
    }
    .wrapper-cookie :nth-child(1) {order: 2;}
    .wrapper-cookie :nth-child(2) {order: 3;}
    .wrapper-cookie :nth-child(3) {order: 1;}
    .cookie-description {
        text-align: justify;
    }
    .cookie-setting {
        width: 85vw;
    }
}