.react-datepicker-wrapper {
    width: auto !important;
}

.react-date-select {
    color: #545454 !important;
    font-size: 14px !important;
    border: 1px solid rgba(0, 96, 70, 0.25) !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    width: 210px !important;
    height: 40px;
    cursor: text;
}

.react-datepicker__header {
    background-color: #006046 !important;
}

.react-datepicker__header .react-datepicker__current-month {
    color: white !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
    border-top: none;
    border-bottom-color: #006046 !important;
}

.react-datepicker__navigation {
    color: #006046 !important;
}

.react-datepicker__day {
    color: #545454 !important;
}
.react-datepicker__day:hover {
    background-color: rgba(0, 96, 70, 0.25) !important;
}
.react-datepicker__day--in-range {
    background-color: #006046 !important;
    color: white !important;
}

.react-datepicker__day-name {
    color: white !important;
}

.react-datepicker__day--in-selecting-range {
    background-color: rgba(0, 96, 70, 0.25) !important;
}

.react-datepicker__day.react-datepicker__day--selected.react-datepicker__day--range-start {
    background-color: #006046 !important;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent !important;
}
.react-datepicker__day.react-datepicker__day--selected {
    background-color: rgba(0, 96, 70, 0.25) !important;
}

.react-datepicker__day--keyboard-selected.react-datepicker__day--today {
    background-color: rgba(0, 96, 70, 0.25) !important;
}

.react-datepicker__close-icon::after {
    background-color: transparent !important;
    color: #79747e !important;
    font-size: 25px !important;
}

.react-datepicker-ignore-onclickoutside:focus {
    border: none !important;
    color: #545454 !important;
}

.react-date-select:focus {
    --tw-ring-color: rgba(0, 96, 70, 0.25) !important;
    border: 1px solid rgba(0, 96, 70, 0.25) !important;
}

.react-datepicker__close-icon {
    right: 4px !important;
    color: #ff0000 !important;
}

[type="text"]:focus,
[type="email"]:focus,
[type="url"]:focus,
[type="password"]:focus,
[type="number"]:focus,
[type="date"]:focus,
[type="datetime-local"]:focus,
[type="month"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="time"]:focus,
[type="week"]:focus,
[multiple]:focus,
textarea:focus,
select:focus {
    box-shadow: none;
}

.break-word {
    word-break: break-word;
}
