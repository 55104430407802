.carousel .control-dots {
    bottom: -10px;
}

.carousel .carousel-slider .control-arrow {
    bottom: 100px;
}

.carousel .carousel-status {
    display: none;
}

.carousel .thumbs-wrapper {
    display: none;
}

.carousel-root {
    margin-top: auto;
    margin-bottom: auto;
}
