/* Loader */
.loader-component {
    position: absolute;
    z-index: 3000;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(1px);
}

/* Remove Cesium attribution */
.cesium-credit-textContainer {
    display: none !important;
}

.cesium-credit-textContainer {
    right: 0;
}

div:has(> .cesium-credit-textContainer) {
    display: block !important;
    right: 0 !important;
}
