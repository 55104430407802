.state-hover-color:enabled:hover svg :is(path, line, circle) {
    fill: #006046;
    stroke: #006046;
}

.button-active svg :is(path, line, circle) {
    fill: #006046;
    stroke: #006046;
}

.button-active {
    background-color: white;
}

.btn-hover:hover svg path {
    stroke: green;
    fill: green;
}

.button-disabled svg :is(path, line, circle) {
    fill: #fefefe;
    opacity: 0.1;
}

.header-tool-wrapper[disabled] {
    /* filter: opacity(1); */
    opacity: 0.7;
    pointer-events: none;
}

@media (max-width: 767px) {
    .btns-color svg path {
        fill: #006046;
    }
    .btns-color:hover svg path {
        fill: white;
    }
}
