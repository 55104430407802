.map-container {
    width: 100vw;
    height: calc(100vh - 64px);
}

.ol-scale-line {
    bottom: 25px;
    right: 18px;
    left: unset;
}

/* Locate me button */
.ol-locate-button {
    top: auto;
    bottom: 58px;
    right: 18px;
    position: fixed;
    background-color: #fefefe;
    border-radius: 30px;
    width: 44px;
    height: 44px;
    z-index: 10;
    box-shadow: 0 4px 15px 0px rgb(0, 0, 0, 10%);
}

.ol-locate-button:hover {
    background-color: #545454;
}

.ol-locate-button:hover svg path {
    fill: white;
}

.ol-control {
    background-color: transparent;
}

/* Zoom to extent button */
.ol-touch .ol-zoom-extent {
    top: auto;
}

.ol-zoom-extent {
    top: 675px;
}

.wrapper svg {
    display: inline;
}

.ol-zoom-extent button {
    width: 44px;
    height: 44px;
    background-image: url("../Media/Icons/zoom_extent.svg");
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    box-shadow: 0 4px 15px 0px rgb(0, 0, 0, 10%);
}

.ol-zoom-extent button:hover {
    background-image: url("../Media/Icons/zoom_extent_hover.svg");
    background-color: #545454;
    color: transparent;
}

.ol-zoom-extent:hover {
    color: #545454;
    outline: none;
}

.ol-zoom-extent button:focus {
    color: transparent;
    outline: none;
}

/* Zoom in/Zoom out buttons */
.ol-control {
    position: fixed;
}

.ol-control button {
    background-color: white;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    font-weight: bolder;
    font-size: 28px;
    font-family: initial;
}

.ol-control button:focus {
    outline: none;
}

.ol-control button:hover {
    background-color: #545454;
    outline: none;
}

.ol-zoom button:hover {
    color: white;
}

.ol-zoom .ol-zoom-in {
    border-radius: 50%;
    box-shadow: 0 4px 15px 0px rgb(0, 0, 0, 10%);
}

.ol-zoom .ol-zoom-out {
    border-radius: 50%;
    box-shadow: 0 4px 15px 0px rgb(0, 0, 0, 10%);
}

.ol-zoom-in {
    margin-bottom: 5px !important;
}

/* Open layers buttons (Zoom, extent) position */
.ol-zoom {
    top: auto;
    bottom: 112px;
    right: 18px;
    left: auto;
}

.ol-zoom-extent {
    top: auto;
    bottom: 217px;
    left: auto;
    right: 18px;
}

/*Set N back after 3D*/
.ol-rotate {
    position: absolute;
}

/*Mobile Menu Layer svg color*/
@media screen and (max-width: 767px) {
    .mobile-layer-icon-closed svg path {
        fill: white;
    }
    .map-container {
        height: calc(100vh - 56px);
    }
    .ol-scale-line {
        bottom: 25px;
    }
    .ol-locate-button {
        top: auto;
        bottom: 55px;
    }
    .ol-zoom {
        top: auto;
        bottom: 104px;
    }
    .ol-zoom-extent {
        bottom: 204px;
    }
    .ol-touch .ol-zoom-extent {
        top: auto;
    }
}
