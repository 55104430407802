/*Move the layer switcher to the lower left corner*/
.layer-switcher {
    top: unset;
    right: unset;
    bottom: 15px;
    left: 10px;
}

/*Layer switcher button style*/
.layer-switcher button {
    width: 85px;
    height: 85px;
    background-image: url("../Media/Images/base.png");
    background-color: transparent;
    background-position: center;
    background-size: cover;
    border: 3px solid #119672;
    border-radius: 10px;
}

/*Layer switcher panel - parent div displayed on the hover button above*/
.layer-switcher .panel {
    border: none;
    border-radius: 10px;
    background-color: transparent;
    width: 180px;
    height: 85px;
}

/*Display of individual base layer in one line*/
.layer-switcher ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0;
}

/*Initially, this button is hidden on hover, but we dont want that*/
.layer-switcher.shown button {
    display: flex;
    font-size: 0;
}

.layer-switcher.shown.ol-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/*Override for radio button*/
.layer-switcher [type="radio"] {
    background-image: url("../Media/Images/baseDOF.png");
    width: 78px;
    height: 78px;
    border: 3px solid white;
    border-radius: 10px;
    opacity: 0.6;
}

.panel ul li:first-child input {
    background-image: url("../Media/Images/baseDOF.png");
}

.panel ul li:last-child input {
    background-image: url("../Media/Images/baseOSM.png");
}

/*Checked radio button - hover*/
.layer-switcher [type="radio"]:checked:hover {
    border-color: #119672;
}

/*Checked radio button*/
.layer-switcher [type="radio"]:checked {
    opacity: 1;
}

/*hover on any base layer*/
.layer-switcher [type="radio"]:hover {
    transform: scale(1.1, 1.1);
    border-color: #119672;
    opacity: 1;
}

li.layer:hover label {
    transform: scale(1.1, 1.1);
    border-radius: 10px 10px 5px 5px;
}

/*Override for first child from parent (ul) panel - input and label*/
.layer-switcher li {
    width: 78px;
    height: 78px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 10px;
}

.layer-switcher li input {
    left: auto;
}

/*Label radio buttons transformed into a white box*/
.layer-switcher li label {
    display: flex;
    margin-top: 0px;
    background-color: white;
    opacity: 0.9;
    z-index: 2;
    position: absolute;
    bottom: 1px;
    width: 72px;
    justify-content: center;
    border-radius: 10px;
    color: #535353;
    font-size: small;
    padding: 0;
}
.panel {
    overflow: hidden;
}
